<template>
  <div>
    <div
      id="appointment-listing"
      class="ml-8"
      v-if="$store.state.auth.user.roles[0].name"
    >
      <h1 class="appointments text-justify text-xl mt-12 text-appointments">
        Cabinet Log Reports
      </h1>
      <div class="flex justify-between sm:flex-row flex-col">
        <button
          class="w-56 bg-create download-btn rounded"
          @click="printDocument"
        >
          Print Document
        </button>

        <div class="lg:ml-40 lg:flex mt-2 sm:mr-40 sm:mt-0">
          <Select
            class="mb-4"
            :values="clusters"
            label="Cluster"
            @selected="setCluster"
            name="CLUSTER"
          />

          <Search class="mb-4" label="Search by name" @input="updateQuery" />
        </div>
      </div>
    </div>
    <div class="document" id="documentToDownload" v-if="documents.length > 0">
      <h1 class="members">ANALYSED DOCUMENTS</h1>
      <div class="members-underline"></div>
      <h1 class="font-bold pt-3 text-xl">Institutions</h1>
      <div>
        <ReportDocument
          v-for="(document, i) in documents"
          :key="i"
          :fileName="document.file.name"
          :brief="document.file.url"
          :received_date="document.createdAt"
          :description="document.title"
          :ministry="document.institution.name"
          :isAppointementReport="false"
        />
      </div>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailable"
      :isLoading="loading"
      type="Reports"
    />
  </div>
</template>

<script>
import Api from "@/services/Apis";
import ReportDocument from "../../components/Listings/ReportDocument.vue";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import Search from "/src/components/searchInput.vue";
import Select from "/src/components/SelectInput.vue";

export default {
  name: "AppointmentReport",
  components: {
    ReportDocument,
    ElementsNotFound,
    Search,
    Select
  },
  data() {
    return {
      appointmentYears: [],
      currentSelectedYear: 2022,
      currentSelectedMonth: 1,
      elementsAvailable: false,
      profiles: [],
      loading: true,
      documents: [],
      statusQuery: "ANALYSIS_IS_DONE",
      clusterQuery: "",
      clusters: [],
    };
  },
  methods: {
    async getAppointmentYears() {
      await Api.get("statistics/appointments/list-years")
        .then((resp) => {
          this.appointmentYears = resp.data;
          this.currentSelectedYear = this.appointmentYears[0].year;
        })
        .catch((error) => {
          console.log(error);
        });
    },

     updateQuery(value) {
      this.query = value;
      // this.searchDocument();
     },

     setCluster(value) {
      this.clusterQuery = value;
      // this.searchDocument();
    },

    onSelectedYearChange(e) {
      this.currentSelectedYear = e.target.value;
      this.getAppointees(this.currentSelectedMonth, this.currentSelectedYear);
    },
    onSelectedMonthChange(e) {
      this.currentSelectedMonth = e.target.value;
      this.getAppointees(this.currentSelectedMonth, this.currentSelectedYear);
    },
    printDocument() {
      var divContents = document.getElementById("documentToDownload").innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      var myWindow = window.open("", "", "height=800, width=800");
      myWindow.document.write("<html>");
      myWindow.document.write("<head>");
      myWindow.document.write("<title> Cok Members");
      myWindow.document.write("</title>");
      myWindow.document.write(stylesHtml);
      myWindow.document.write("<head/>");
      myWindow.document.write("<body >");
      myWindow.document.write(divContents);
      myWindow.document.write("</body></html>");
      myWindow.document.close();
      myWindow.print();
    },

    async searchDocument() {
      await Api.get(
        `documents/search-many/cluster/${
          this.clusterQuery === "" ? "ALL" : this.clusterQuery
        }/institution/ALL/status/${this.statusQuery === "" ? "ALL" : this.statusQuery}?name=""&limit=1000&page=0`
      )
        .then((resp) => {
          this.documents = resp.data.content;
          console.log(this.documents.length);
          if (this.documents.length>0) {
            this.elementsAvailable = true;
          } else {
            this.elementsAvailable = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAppointees(month, year) {
      await Api.get(`appointee/report/${month}/${year}`)
        .then((response) => {
          this.profiles = response.data.data;
          this.loading = false;
          this.elementsAvailable = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getDocuments() {
      await Api.get("documents").then((response) => {
        this.documents = response.data.content;
        if (!this.documents.length) {
          this.elementsAvailble = false;
        } else {
          this.elementsAvailble = true;
        }
        this.loading = false;
      });
    },

    async getDocumentByStatus(value) {
      if (value !== "") {
        if (value === "All") {
          this.getDocuments();
        } else {
          await Api.get("documents/status/" + value).then((resp) => {
            this.documents = resp.data.content;
            console.log(this.documents.length)
            if (!this.documents.length) {
              this.elementsAvailable = false;
            } else {
              this.elementsAvailable = true;
            }
          });
        }
      }
    }
  },
  created() {
    this.getAppointmentYears();
    this.getAppointees(this.currentSelectedMonth, this.currentSelectedYear);
    // this.searchDocument();
    this.getDocumentByStatus(this.statusQuery)
  },
};
</script>

<style scoped>
#documentToDownload {
  width: 60%;
  min-width: 65%;
  height: auto;
  min-height: 100%;
  background: white;
  margin: 2rem 0 0 2rem;
  padding: 1rem 1rem;
  color: #2c3e50;
}
.members {
  text-align: center;
  font-weight: 600;
}
.download-btn {
  color: white;
  margin-top: 1rem;
  height: 2.5rem;
}
.members-underline {
  height: 0.1rem;
  background-color: black;
  border: 1px solid black;
}
@media only screen and (max-width: 760px) {
  #documentToDownload {
    width: 80%;
    min-width: 100%;
    padding: 0;
  }
}
</style>
