var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"flex flex-col sm:flex-row gap-0 sm:gap-32"},[(_vm.fileName)?_c('img',{staticClass:"mt-2",attrs:{"src":("/icons/" + (_vm.fileName.includes('zip')
          ? 'zip.svg'
          : _vm.fileName.includes('doc') || _vm.fileName.includes('docx')
          ? 'docx.png'
          : _vm.fileName.includes('pdf')
          ? 'pdf.png'
          : _vm.fileName.includes('xlsx')
          ? 'xlsx.png'
          : _vm.fileName.includes('pptx') || _vm.fileName.includes('ppt')
          ? 'pptx.png'
          : '')),"alt":""}}):_vm._e(),_c('div',{staticClass:"mt-2"},[(_vm.fields['ministry'])?_c('h2',[_c('b'),_vm._v(" "+_vm._s(_vm.ministry || "Not provided")+" ")]):_vm._e(),(_vm.fields['description'])?_c('h2',[_c('b',[_vm._v("Description : ")]),_vm._v(_vm._s(_vm.description || "Not provided")+" ")]):_vm._e(),(_vm.fields['received_date'])?_c('h2',[_c('b',[_vm._v("Received Date : ")]),_vm._v(_vm._s(_vm.received_date || "Not provided")+" ")]):_vm._e(),(_vm.fields['brief'])?_c('a',{on:{"click":function($event){return _vm.download(("" + _vm.brief))}}},[_vm._v("Final brief")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }