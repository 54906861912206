<template>
  <div class="mt-10">
    <div class="flex flex-col sm:flex-row gap-0 sm:gap-32">
      <img
        v-if="fileName"
        :src="`/icons/${
          fileName.includes('zip')
            ? 'zip.svg'
            : fileName.includes('doc') || fileName.includes('docx')
            ? 'docx.png'
            : fileName.includes('pdf')
            ? 'pdf.png'
            : fileName.includes('xlsx')
            ? 'xlsx.png'
            : fileName.includes('pptx') || fileName.includes('ppt')
            ? 'pptx.png'
            : ''
        }`"
        alt=""
        class="mt-2"
      />
      <div class="mt-2">
        <h2 v-if="fields['ministry']">
          <b></b> {{ ministry || "Not provided" }}
        </h2>
        <h2 v-if="fields['description']">
          <b>Description : </b>{{ description || "Not provided" }}
        </h2>

        <h2 v-if="fields['received_date']">
          <b>Received Date : </b>{{ received_date || "Not provided" }}
        </h2>
        <a v-if="fields['brief']" @click="download(`${brief}`)">Final brief</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ReportDocument",
  components: {
  },

  data() {
    return {
      image: "",
      fields: [],
      imgSrc: "",
    };
  },
  props: {
    analyst: {
      type: String,
    },
    ministry: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    received_date: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    brief: {
      type: String,
      required: true,
    },
  },
  async created() {
    this.fields = await this.getFields("cabinet_log_reports_list");
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    download(url) {
      window.open(
        `${url}`,
        "_blank" // <- This is what makes it open in a new window.
      );
    },

    setDocumentImg() {
      switch (this.fileType.split("/")) {
        case "pdf":
          this.imgSrc = "";
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 10%;
  height: 20%;
  object-fit: contain;
}

a {
  font-size: 15px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 760px) {
  img {
    width: 40%;
  }
}
</style>
