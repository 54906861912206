<template>
  <div>
    <div v-if="name != 'STATUS'">
      <select class="my-select-container" @change="getCluster" v-model="value">
        <option value="" disabled selected>{{ `Select by ${label}` }}</option>
        <option value="ALL">ALL</option>
        <option v-for="value in values" :key="value.id" :value="value.id">
          {{ value.name }}
        </option>
      </select>
    </div>

    <div v-if="name === 'STATUS'">
      <select class="my-select-container" @change="getCluster" v-model="value">
        <option value="" disabled selected>{{ `Select by ${label}` }}</option>
        <option value="ALL">ALL</option>
        <option v-for="value in values" :key="value.id" :value="value.name">
          {{ value.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  data: () => ({
    showLabel: true,
    value: "",
  }),
  props: {
    values: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    defaultValue: {
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    getCluster() {
      this.$emit("selected", this.value);
    },
  },
   created() {
    this.value = this.defaultValue;
    this.id = `input-${Math.random()}`;
    this.getCluster();
  },
  watch: {
    selected() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss">
.my-select-container {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(22, 121, 168, 0.25);
  border-radius: 6px;
  padding: 9px 14px;
  width: 14rem;
  margin: 0 1rem 0 0;
  outline: 0;
}
// @import "../../assets/scss/imports/colors.scss";
// .my-search-container {
//   background: #ffffff;
//   box-shadow: 0px 2px 6px rgba(22, 121, 168, 0.25);
//   border-radius: 6px;
//   padding: 9px 14px;
//   input {
//     width: 90%;
//     &:focus {
//       outline: none;
//     }
//   }
//   font-family: Inter;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 15px;
//   svg {
//     fill: #032b3d;
//   }
//   label,
//   input {
//     color: #4c4c4c;
//   }
//   &.grey {
//     svg {
//       fill: #9d9ca5;
//     }
//     label,
//     input {
//       color: #bbbabd;
//     }
//   }
// }
</style>
